import { gsap } from 'gsap';
import { masterAnimation, setInitialStates } from './animations';

// Set initial states for elements
setInitialStates();

// Create a master timeline to control all animations
document.addEventListener("DOMContentLoaded", function () {
  masterAnimation();
});
